<template>
  <div v-if="loading">
  </div>
  <div v-else-if="isFinished">
    <CSidebar 
      fixed
      :minimize="false"
      :show="true"
    >
      <CSidebarBrand class="d-md-down-none" style="text-decoration: none;">
        <div class="c-sidebar-brand-full">
          <CRow>
            <CIcon name="cil-blur" style="margin-top:7px; width: 30px !important; height: 30px !important;"/>
            <h1>SRI</h1>
            <CBadge color="warning" style="height: 15px; margin-top: 20px">Bank</CBadge>
          </CRow>
        </div>
        <CIcon class="c-sidebar-brand-minimized" name="cil-blur" />
      </CSidebarBrand>
    </CSidebar>
    <CWrapper>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid> 
            <CRow class="justify-content-center">
              <CCol lg="12" xl="10">
                <CCard>
                  <CCardHeader>    
                    <div class="clearfix">
                      <h4 class="pt-3">Processo finalizado.</h4>
                      <p class="text-muted">Aguarde. Em breve entraremos em contato.</p>
                    </div>
                  </CCardHeader>
                </CCard>
              </CCol>
            </CRow>
          </CContainer>
        </main>
      </div>
    </CWrapper>
  </div>
  <div v-else-if="token" class="c-app"  style="background-image: linear-gradient(to bottom right, #3c4b64 , purple);">
    <CSidebar 
      fixed
      :minimize="false"
      :show="true"
    >
      <CSidebarBrand class="d-md-down-none" style="text-decoration: none;">
        <div class="c-sidebar-brand-full">
          <CRow>
            <CIcon name="cil-blur" style="margin-top:7px; width: 30px !important; height: 30px !important;"/>
            <h1>SRI</h1>
            <CBadge color="warning" style="height: 15px; margin-top: 20px">Bank</CBadge>
          </CRow>
        </div>
        <CIcon class="c-sidebar-brand-minimized" name="cil-blur" />
      </CSidebarBrand>
    </CSidebar>
    <CWrapper>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid> 
            <CRow class="justify-content-center">
              <CCol lg="12" xl="10">
                <CCard style="min-height:600px">
                  <CCardHeader>
                    Confirme seus dados
                  </CCardHeader>
                  <CCardBody>
                    <CTabs  :active-tab.sync="activeTabView" variant="pills">
                      <CTab disabled>
                        <template slot="title">
                          Básico
                          <CIcon v-if="activeTabView>0" name="cil-check"/>
                        </template>
                        <CRow class="form-group-company" >
                          <CCol sm="12">
                            <CInput 
                              v-model="form.company_name" 
                              class="mb-0" 
                              v-uppercase
                              label="Razão Social"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12">
                            <CInput 
                              v-model="form.fantasy_name" 
                              class="mb-0" 
                              v-uppercase
                              label="Nome Fantasia"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" md="6">
                            <CInput 
                              v-model="form.cnpj" 
                              class="mb-0"
                              label="CNPJ"
                              v-mask="'##.###.###/####-##'"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" md="6">
                            <CInput 
                              v-model="form.cae" 
                              class="mb-0" 
                              label="CAE"
                              disabled
                            />
                          </CCol>
                          <CCol sm="8" lg="10">
                            <CInput 
                              v-model="form.ie" 
                              class="mb-0"
                              label="Insc. Estadual"
                              disabled
                            />
                          </CCol>
                          <CCol sm="4" lg="2">
                            <div v-if="!isMobile"><br /><br /></div>
                            <CInputCheckbox label="ISENTO" :checked="form.ie==='ISENTO'" disabled />
                          </CCol>
                          <CCol sm="8" lg="10">
                            <CInput 
                              v-model="form.im"
                              class="mb-0"
                              label="Insc. Municipal"
                              disabled
                            />
                          </CCol>
                          <CCol sm="4" lg="2">
                            <div v-if="!isMobile"><br /><br /></div>
                            <CInputCheckbox label="ISENTO" :checked="form.im==='ISENTO'" disabled/>
                          </CCol>
                        </CRow>
                      </CTab>
                      <CTab disabled>
                        <template slot="title">
                          Endereço
                          <CIcon v-if="activeTabView>1" name="cil-check"/>
                        </template>
                        <CRow class="form-group-company">
                          <CCol sm="12" lg="3">
                            <CInput 
                              v-model="form.address.zip_code" 
                              class="mb-0"
                              label="CEP"
                              v-mask="'#####-###'"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" lg="6">
                            <CInput 
                              v-model="form.address.address1" 
                              class="mb-0" 
                              v-uppercase
                              label="Endereço"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" lg="3">
                            <CInput 
                              v-model="form.address.address_number" 
                              class="mb-0" 
                              v-uppercase 
                              label="Número"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" lg="5">
                            <CInput 
                              v-model="form.address.address2" 
                              class="mb-0"
                              label="Complemento"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" lg="7">
                            <CInput 
                              v-model="form.address.district" 
                              class="mb-0"
                              label="Bairro"
                              v-uppercase 
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" lg="9">
                            <CInput 
                              v-model="form.address.city" 
                              class="mb-0"
                              label="Cidade"
                              v-uppercase 
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" lg="3">
                            <CInput 
                              v-model="form.address.uf" 
                              class="mb-0" 
                              label="UF"
                              v-uppercase
                              disabled
                            />
                          </CCol>
                        </CRow>
                      </CTab>
                      <CTab disabled>
                        <template slot="title">
                          Representante
                          <CIcon v-if="activeTabView>2" name="cil-check"/>
                        </template>
                        <CRow class="form-group-company">
                          <CCol sm="12" md="8">
                            <CInput 
                              v-model="form.representative.name" 
                              class="mb-0" 
                              v-uppercase
                              label="Nome"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" md="4">
                            <CInput 
                              v-model="form.representative.tax_id" 
                              class="mb-0"
                              label="CPF"
                              v-mask="'###.###.###-##'"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" md="7">
                            <CInput 
                              v-model="form.representative.mother" 
                              class="mb-0"
                              label="Nome da Mãe"
                              v-uppercase
                              disabled
                            />
                          </CCol>
                          <CCol sm="8" lg="5">
                            <CInput 
                              v-model="form.representative.birth_date" 
                              class="mb-0"
                              label="Data de Nascimento"
                              type="date"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" lg="5">
                            <CInput 
                              v-model="form.representative.phone" 
                              class="mb-0"
                              label="Celular"
                              v-mask="'(##)#####-####'"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" lg="7">
                            <CInput 
                              v-model="form.representative.email" 
                              class="mb-0"
                              label="Email"               
                              v-lowercase
                              disabled
                            />
                          </CCol>
                        </CRow>          
                      </CTab>
                      <CTab disabled>
                        <template slot="title">
                          End. Represetante
                          <CIcon v-if="activeTabView>3" name="cil-check"/>
                        </template>
                        <CRow>
                          <CCol sm="12" lg="3">
                            <CInput 
                              v-model="form.representative.address.zip_code" 
                              class="mb-0"
                              label="CEP"
                              v-mask="'#####-###'"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" lg="6">
                            <CInput 
                              v-model="form.representative.address.address1" 
                              class="mb-0" 
                              v-uppercase
                              label="Endereço"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" lg="3">
                            <CInput 
                              v-model="form.representative.address.address_number" 
                              class="mb-0" 
                              v-uppercase 
                              label="Número"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" lg="5">
                            <CInput 
                              v-model="form.representative.address.address2" 
                              class="mb-0"
                              label="Complemento"
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" lg="7">
                            <CInput 
                              v-model="form.representative.address.district" 
                              class="mb-0"
                              label="Bairro"
                              v-uppercase
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" lg="9">
                            <CInput 
                              v-model="form.representative.address.city" 
                              class="mb-0" 
                              label="Cidade"
                              v-uppercase
                              disabled
                            />
                          </CCol>
                          <CCol sm="12" lg="3">
                            <CInput 
                              v-model="form.representative.address.uf" 
                              class="mb-0" 
                              label="UF"
                              disabled
                            />
                          </CCol>           
                        </CRow>
                      </CTab>
                      <CTab disabled>
                        <template slot="title">
                            Contrato
                            <CIcon v-if="activeTabView>4" name="cil-check"/>
                        </template>
                        <CJumbotron style="text-align: justify;">
                          <div  ref="content">
                            <h3 class="text-center"><b><u>PROCURAÇÃO</u></b></h3>
                            <p class="lead">
                              Pelo presente instrumento particular de procuração (“<u>Procuração</u>”),
                              <b>{{ form.company_name }}</b>, inscrita no CNPJ/MF sob o nº {{form.cnpj}},
                              com sede na {{form.address.address1}} N° {{form.address.address_number}}  
                              {{form.address.district}}, {{form.address.city}}-{{form.address.uf}}
                              neste ato representada por <b>{{form.representative.name}}</b>,
                              inscrito(a)(s) no CPF/MF nº {{form.representative.tax_id}} (“Outorgante”),
                              nomeia e constitui sua bastante procuradora, <b>MCQ DESENVOLVIMENTO DE SISTEMAS
                              E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR EIRELI</b>,
                              inscrita no CNPJ/MF sob o nº <b>26.191.804/0001-09</b>, 
                              com sede na Rua Silveira Lobo 32 Cxpst 1188, na cidade de RECIFE, estado de PERNAMBUCO,
                              CEP: 52061-030 (“<u>Procuradora</u>”), conferindo-lhe
                              poderes amplos para agir em nome do Outorgante para:
                            </p>
                            <br />
                            <li class="lead">
                              abertura da conta de pagamento (“<u>Conta de Pagamento</u>”) perante a instituição
                              de pagamento que presta serviços pagamento por meio de seu sistema (“FLAGSHIP”) 
                              e todos os poderes que se fizerem necessários, especialmente para realizar 
                              a administração e movimentação da respectiva Conta de Pagamento; e
                            </li>
                            <li class="lead" >
                              autorizar o resgate dos valores disponíveis na Conta de Pagamento, 
                              para uma conta bancária de mesma titularidade do Outorgante.
                              O Outorgante poderá, a qualquer tempo, revogar os poderes outorgados 
                              mediante comunicação à PARCEIRA, com antecedência de 30 (trinta) dias.
                              Esta Procuração permanecerá vigente enquanto ainda estiver em vigor 
                              os Termos e Condições de Uso da Plataforma celebrado com a FLAGSHIP.
                            </li>
                            <br />
                            <p class="lead">
                              Recife, {{(new Date()).getDate()}} de {{meses[(new Date()).getMonth()]}} de {{(new Date()).getFullYear()}}.
                            </p>
                            <div style="position: relative; height: 10em; width:100%">
                              <div  v-if="!image">
                                <label  @click="onPickFile" style="color: blue; cursor:pointer; position:absolute;bottom:0px;" > 
                                  Click <u>aqui</u> para carregar sua assinatura e aceitar os termos acima
                                </label>
                              </div>
                              <img  v-else :src="image" class="uploading-image"  @click="onPickFile" />
                            </div>
                            <b><hr class="my-1"></b>
                            <h6 class="text-center">{{form.representative.name}}</h6>                          
                          </div>
                        </CJumbotron>
                      </CTab>
                    </CTabs>
                  </CCardBody>
                  <CRow>
                    <CCol sm="12" style="margin-left:15px">
                        <h3> Os dados estão corretos?</h3>
                    </CCol>
                  </CRow>
                  <CCardFooter>
                    <CRow class="justify-content-center">
                      <CCol sm="12"  md="6">
                        <CButton type="reset" size="sm" color="danger" >
                          <CIcon name="cil-ban"/> 
                          {{labRedButton}}
                        </CButton>
                      </CCol>
                      <CCol sm="12" md="6">
                        <CButton v-on:click="nextTab" size="sm" color="primary" :disabled="(activeTabView===4) && !image">
                          <CIcon name="cil-check-circle"/> 
                          {{labBlueButton}}
                        </CButton>
                      </CCol>                      
                    </CRow>
                  </CCardFooter>
                </CCard>
              </CCol>
            </CRow>
          </CContainer>
        </main>
      </div>
    </CWrapper>
    <input
      type="file"
      style="display: none"
      ref="fileInput"
      accept="image/png"
      @change="onFilePicked" />
  </div>  
  <div v-else>
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100 justify-content-center">
        <CCol md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">500</h1>
            <h4 class="pt-3">Houston, temos um problema!</h4>
            <p class="text-muted">A página que você está procurando expirou ou está temporariamente indisponível.</p>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>

</template>

<script>
import functions from "@/functions";
const axios = require("axios").default;
import jsPDF from 'jspdf';
import domtoimage from "dom-to-image";

export default {
  name: 'Validator',
  data(){
    return{
      activeTabView: 0,
      isMobile: false,
      token: '',
      form:{
        company_name:'',
        fantasy_name:'',
        cnpj:'',
        ie:'',
        im:'',
        cae:'',
        establishment_date:'',
        email:'',
        phone:'',
        note:'',
        address:{
          address1:'',
          address2:'',
          address_number:'',
          district:'',
          city:'',
          state:'',
          zip_code:'',   
        },
        representative:{
          name:'',
          mother:'',
          tax_id:'',
          birth_date:'',
          email:'',
          phone:'',
          address:{
            address1:'',
            address2:'',
            address_number:'',
            district:'',
            city:'',
            state:'',
            zip_code:'',
          }
        }
      },
      meses: new Array("Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"),
      image:null,
      labBlueButton:'',
      labRedButton:'',
      constants: new Array(
        "Não, quero reportar!",
        "Sim, pode prosseguir.",
        "Sim, fazer o download e concluir.",
      ),
      isFinished: false,
      loading: true
    }
  },
  methods:{
    ...functions,
    nextTab(){
      if (this.activeTabView===3) 
        this.labBlueButton=this.constants[2];
      if (this.activeTabView<4){
        return this.activeTabView++
      }
      if (this.activeTabView===4) {
        this.downloadWithCSS();
      }
    },
    onResize () {
        this.isMobile = window.innerWidth < 576;
        this.isFullDesktop = window.innerWidth > 1300;
    },
    getData(token){
      if (!token){
        return
      }
      const config = {
        method: 'get',
        url: this.vhost('agreement'),
        params: {
            token,
        }
      }
      axios(config)
        .then((con) => {
          this.form = con.data.data;
          this.form.address.uf = this.stateToUF(this.form.address.state)
          this.form.representative.address.uf = this.stateToUF(this.form.representative.address.state);
          this.token= token;
          this.loading= false;
        })
        .catch(()=>{
          this.token=undefined;
          this.loading= false;
        })
    },
    onPickFile () {
      this.$refs.fileInput.click()
    },
    onFilePicked (event) {
      var vm = this;      
      const image1 = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image1);
      reader.onload = e =>{
          var image = new Image();          
          image.onload = function () {
              var canvas = document.createElement('canvas'),
                  max_size = 600,
                  width = image.width,
                  height = image.height;
              if (width > height) {
                  if (width > max_size) {
                      height *= max_size / width;
                      width = max_size;
                  }
              } else {
                  if (height > max_size) {
                      width *= max_size / height;
                      height = max_size;
                  }
              }
              canvas.width = width;
              canvas.height = height;
              canvas.getContext('2d').drawImage(image, 0, 0, width, height);
              vm.image = canvas.toDataURL('image/jpg');
          }
          image.src = e.target.result;
      };
    },
    downloadWithCSS() {
      const vm = this;
      domtoimage
        .toPng(this.$refs.content)
        .then(function(dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          const doc = new jsPDF('p', 'mm', [297, 210]);
          doc.addImage(img, "JPEG", 10, 20, 190, 200);
          var blob = doc.output('blob');
          var fileReader = new FileReader();
          fileReader.onload = function() {
            const dataUrl = fileReader.result;
            const base64 = dataUrl.split(',')[1];
            vm.setData(base64, vm.token);
          };
          fileReader.readAsDataURL(blob);
        })
        .catch(function(error) {
          console.error("oops, something went wrong!", error);
        });
    },
    setData(base64, token){
      if (!token || !base64){
        return
      }      
      const config = {
        method: 'post',
        url: this.vhost('agreement/update'),
        data: {
            token,
            procuration: base64,
        }
      }
      axios(config)
        .then(() => {
          this.isFinished= true;
          this.token     = undefined;
          const date = new Date();
          const filename =
            "procuracao_" +
            date.getFullYear() +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            ("0" + date.getDate()).slice(-2) +
            ("0" + date.getHours()).slice(-2) +
            ("0" + date.getMinutes()).slice(-2) +
            ("0" + date.getSeconds()).slice(-2) +
            ".pdf";
          var link = document.createElement("a");
          link.download = filename;
          link.href = 'data:application/pdf;base64,' + base64;
          document.body.appendChild(link);
          link.click();
        })
        .catch((error)=> {
          this.token     = undefined;
          console.error(error.message);
        });
    }
  },
  mounted () {
      this.onResize();
      window.addEventListener('resize', this.onResize, { passive: true });      
  },
  created(){
    var token;
    if (this.$route.query && this.$route.query.token){
      token = this.$route.query.token
    }
    this.labBlueButton= this.constants[1];
    this.labRedButton = this.constants[0];
    this.getData(token);
  }

}
</script>
<style>
    .uploading-image{
        position:absolute;
        margin-left: auto;
        margin-right: auto;
        max-height: 10em;
        bottom: 0px;
    }
    .bg_black {
        background: #000;
        height: 100vh;
    }
    .bg_black img {
      position: absolute;
      width: 100px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
</style>
